import { h, Component } from "preact";
import "./style.css";

class Modal extends Component {
  constructor() {
    super();
    this.className = "mnt-Modal";
    this.events = {
      onActivate: "mnt-activateModal",
    };
    this.state = {
      active: false,
      loaded: false,
      targetUrl: null,
    };
  }

  componentDidMount() {
    document.addEventListener(
      this.events.onActivate,
      this.handleActivate.bind(this),
      false
    );
  }

  handleActivate(e) {
    const { slug } = e.detail;
    this.setState({
      active: true,
      targetUrl: this.props.targetUrls[slug],
    });

    this.escListener = document.addEventListener("keydown", (e) => {
      let code;

      if (e.key !== undefined) {
        code = e.key;
      } else if (e.keyIdentifier !== undefined) {
        code = e.keyIdentifier;
      } else if (e.keyCode !== undefined) {
        code = e.keyCode;
      }

      const isEscape = code === 27 || code === "Escape";
      if (isEscape) {
        this.handleCloseRequest();
      }
    });
  }

  handleIFrameLoad() {
    this.setState({
      loaded: true,
    });
  }

  handleCloseRequest() {
    this.setState({
      active: false,
      loaded: false,
      targetUrl: null,
    });

    document.removeEventListener("keydown", this.escListener);
  }

  render() {
    const { active, loaded } = this.state;
    const classNames =
      `${this.className}` +
      (active ? ` ${this.className}--active` : "") +
      (loaded ? ` ${this.className}--loaded` : "");

    const content = active ? (
      <div className={this.className + "-content"}>
        <button onClick={this.handleCloseRequest.bind(this)}>&times;</button>
        <iframe
          className={this.className + "-iframe"}
          onLoad={this.handleIFrameLoad.bind(this)}
          src={this.state.targetUrl}
        />
      </div>
    ) : (
      ""
    );

    const loading = loaded ? (
      ""
    ) : (
      <div className={this.className + "-loading"}>Loading</div>
    );

    return (
      <div className={classNames}>
        <div
          className={this.className + "-backdrop"}
          onClick={this.handleCloseRequest.bind(this)}
        ></div>
        {content}
        {loading}
      </div>
    );
  }
}

export default Modal;
