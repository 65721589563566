import { h, Component } from "preact";
import "./style.css";

class TopBar extends Component {
  handleClick() {
    document.dispatchEvent(
      new CustomEvent("mnt-activateModal", {
        detail: { slug: this.props.slug },
      })
    );
  }

  render() {
    return (
      <div className="mnt-TopBar" onClick={this.handleClick.bind(this)}>
        {this.props.description}
      </div>
    );
  }
}

export default TopBar;
