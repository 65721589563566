import { h, Component } from "preact";

import "./style.css";

class BottomCorner extends Component {
  handleClick() {
    document.dispatchEvent(
      new CustomEvent("mnt-activateModal", {
        detail: { slug: this.props.slug },
      })
    );
  }

  render() {
    return (
      <div className="mnt-BottomCorner" onClick={this.handleClick.bind(this)}>
        <div className="mnt-BottomCorner-icon">?!</div>
        <div className="mnt-BottomCorner-tooltip">{this.props.description}</div>
      </div>
    );
  }
}

export default BottomCorner;
