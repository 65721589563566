export default ({ url }) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        switch (xhr.status) {
          case 200:
            resolve(xhr);
            break;
          case 404:
            throw new Error("Meenit not found. Check the provided slug.");
          case 401:
            throw new Error(
              "Access not permitted. Your domain is not part of the whitelist."
            );
          default:
            throw new Error("Error occured. Check your setup.");
        }
      }
    };

    xhr.onerror = (err) => {
      console.error(
        "It seems that Meenit is currently not available. Please check your connection.",
        err
      );
      reject(err);
    };

    xhr.open("GET", url, true);
    xhr.setRequestHeader("Content-Type", "text/plain");
    xhr.send(null);
  });
};
