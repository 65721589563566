import { h, render } from "preact";
import { host } from "../config.json";

require("es6-promise").polyfill();

import TopBar from "./components/top-bar/index.jsx";
import BottomCorner from "./components/bottom-corner/index.jsx";
import Modal from "./components/modal/index.jsx";
import fetchUrl from "./helpers/fetchUrl";

const HOST = IS_DEVELOPMENT ? "http://localhost:3000" : host;

class MeenitIntegrator {
  constructor(opts = {}) {
    this.targetUrls = [];
    this.host = opts.host;
    this.registeredComponents = [];

    this.registerComponent({ type: "modal" });
    setTimeout(() => {
      this.getContent();
    }, 10);
  }

  async getContent() {
    await Promise.all(
      Object.values(this.targetUrls).map((url) => fetchUrl({ url }))
    );
    this.renderComponents();
  }

  renderComponents() {
    const body = document.getElementsByTagName("body")[0];
    let el, component;
    this.registeredComponents.forEach((componentData) => {
      const { slug, type, description } = componentData;
      el = document.createElement("div");
      el.id = `mnt-${type}`;
      body.appendChild(el);

      switch (type) {
        case "top-bar":
          component = <TopBar slug={slug} description={description} />;
          break;
        case "bottom-corner":
          component = <BottomCorner slug={slug} description={description} />;
          break;
        case "modal":
          component = <Modal targetUrls={this.targetUrls} />;
          break;
        default:
          throw new Error(`No component found for ${type}`);
      }
      render(component, el);
    });
  }

  registerComponent({ slug, type, description = "" }) {
    if (["top-bar", "bottom-corner", "modal"].indexOf(type) > -1) {
      this.registeredComponents.push({ slug, type, description });
      if (slug) {
        this.targetUrls[slug] = this.getTargetUrl({ slug });
      }
    }
  }

  registerLinks({ selector, slug }) {
    const linkEls = document.querySelectorAll(selector);
    this.targetUrls[slug] = this.getTargetUrl({ slug });

    for (let i = 0; i < linkEls.length; i++) {
      linkEls[i].addEventListener(
        "click",
        function (e) {
          e.preventDefault();
          document.dispatchEvent(
            new CustomEvent("mnt-activateModal", { detail: { slug } })
          );
        },
        false
      );
    }
  }

  getTargetUrl({ slug }) {
    return `${this.host || HOST}/app/integration/${slug}`;
  }
}

export default MeenitIntegrator;
